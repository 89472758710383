const settings = {
    // brand_primary  : '#da0428',
    brand_primary  : '#0097A7',
    // brand_secondary: '#1d1d1d',
    brand_secondary: '#634E42',
    gray_lighter   : '#d8d8d8',
    gray_lightest  : '#f4f4f4',
    white          : '#fff',
    ease           : [.645,.045,.355,1],
    transition     : 500,
    transition_fast: 300,
    transition_slow: 800,
    transition__inS     : .500,
    transition_fast__inS: .30,
    transition_slow__inS: .800,
}

export default settings