import Util from './util'

const SELECTORS = {
  pageLinks: 'a[href]'
}

const CLASSES = {
  gallery: 'js_gallery',
  lightbox: 'lightbox',
  noTransition: 'no-pagetransition'
}

export default class PageTransition {
  constructor (App) {
    this.App       = App
    this.pageLinks = Array.from( document.querySelectorAll(SELECTORS.pageLinks) )
    if (this.pageLinks.length) {
      this.init()
    }
  }

  init() {
    const onLinkClick = e => {
      const link = Util.findParentByTagName(e.target || e.srcElement, 'A')

      if ( !Util.hasClass(link, CLASSES.lightbox) && !Util.hasClass(link, CLASSES.gallery) ) {
        // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
        // and open the image in a new tab
        if (e.metaKey || e.ctrlKey || e.target.getAttribute('target')) {
          // return w.open(element.src, '_blank');
          return
        }

        // e.preventDefault()

        import('./pageTransitionAnimation').then((module) => {
          new module.PageTransitionAnimation(link.href)
        })
      }
    }

    this.pageLinks.forEach(l => {
      const href = l.href

      if (
        !Util.hasClass(l, CLASSES.noTransition)
        && !Util.hasClass(l, CLASSES.lightbox)
        && !Util.hasClass(l, CLASSES.gallery)
        && !Util.hasClass(l, 'cn-button')
        && !Util.hasClass(l, 'PQP-widget__link')
        && (
          (
            Util.isSiteUrl(href)
            && href.indexOf('#') === -1
          )
          || Util.hasClass('project-link')
        )
        && href.indexOf('.zip') === -1
        && href.indexOf('mailto:') === -1
      ) {
        l.addEventListener('click', onLinkClick)
      }
    })
  }
}
