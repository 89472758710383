import '@js/modules/skip-link-focus-fix.js'
import '@js/modules/polyfill'
import Promise from 'es6-promise-promise'
import modernizr from '@js/vendor/modernizr-3.6.0.min.js'
// import quicklink from 'quicklink'
import variables from '@js/variables'
import debug from '@js/modules/debug'
import {hasClass} from '@js/modules/helper'
import Cookie from '@js/modules/cookie'
import Navigation from '@js/modules/navigation'
import PageTransition from '@js/modules/pageTransition'
import SmoothScroll from '@js/modules/scroll'
import * as base from '@js/modules/base'


const SELECTORS = {
  loaderProgress: '#pageloader .pageloader__progress',

  linkWords: '.link-words',
  linkLetters: '.link-letters',

  svgAnim: '.svg-animate',

  blockReveal: '.blockReveal',
  scrollReveal: '.scrollReveal',
  sliceReveal: '.sliceReveal',

  postslideshow: '.postslideshow',

  parallax: '.item-parallax:not(.no-instance)'
}
const CLASSES = {
  loading: 'loading',
  loaded: 'loaded',
  animation: 'fade-in'
}


// Main App Object
const App = {

  loadTotal: 0,
  loadProgress: 0,
  modulesToInit: {},

  init() {
    const t = this
    this.isIE = navigator.userAgent.indexOf('MSIE ') > 0 || navigator.userAgent.match(/Trident.*rv\:11\./) !== null
    this.isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null
    document.documentElement.classList.add( this.isIE ? 'isIE' : 'isNotIE' )
    document.documentElement.classList.add( this.isMobile ? 'isMobile' : 'isNotMobile' )

    this.pageloaderProgress            = document.querySelector(SELECTORS.loaderProgress)
    this.modulesToInit['PageLoader']   = 1
    this.modulesToInit['Gallery']      = 1
    this.modulesToInit['Lightbox']     = 1
    this.body                          = document.body
    // document.documentElement.classList.remove('no-js');

    // this.initQuicklink()
    this.registerServiceWorker()
    this.Cookie         = new Cookie(this)
    this.Navigation     = new Navigation(this)
    this.PageTransition = new PageTransition(this)
    this.SmoothScroll   = new SmoothScroll(this)

    // All pages > init scripts if there is at least one
    if ( this.body.querySelector(SELECTORS.linkWords) || this.body.querySelector(SELECTORS.linkLetters) ) {
      import('@js/modules/link').then(function (module) {
        const Link = module.default
        t.Link = new Link(t)
      })
    }
    if ( !this.isIE && this.body.querySelector(SELECTORS.svgAnim) ) {
      this.modulesToInit['SvgAnimate'] = 1
      import('@js/modules/svgAnimate').then(function (module) {
        const SvgAnimate = module.default
        t.SvgAnimate = new SvgAnimate(t)
      })
    }
    // if ( this.body.querySelector(SELECTORS.parallax) ) {
    //   this.modulesToInit['Parallax'] = 1
    //   import('@js/modules/parallax').then(function (module) {
    //     const Parallax = module.default
    //     t.Parallax = new Parallax(t)
    //   })
    // }
    if ( this.body.querySelector(SELECTORS.sliceReveal) ) {
      this.modulesToInit['SliceReveal'] = 1
      import('@js/modules/sliceReveal').then(function (module) {
        const SliceRevealer = module.default
        t.SliceReveal = new SliceRevealer(t)
      })
    }
    if ( this.body.querySelector(SELECTORS.blockReveal) ) {
      this.modulesToInit['BlockReveal'] = 1
      import('@js/modules/blockReveal').then(function (module) {
        const BlockRevealer = module.default
        t.BlockReveal = new BlockRevealer(t)
      })
    }
    if ( this.body.querySelector(SELECTORS.scrollReveal) ) {
      this.modulesToInit['ScrollReveal'] = 1
      import('@js/modules/scrollReveal').then(function (module) {
        const ScrollRevealer = module.default
        t.ScrollReveal = new ScrollRevealer(t)
      })
    }
    if ( !this.isIE && this.body.querySelector(SELECTORS.postslideshow) ) {
      this.modulesToInit['PostSlideshow'] = 1
      import('@js/modules/postslideshow').then(function (module) {
        const PostSlideshow = module.default
        t.PostSlideshow = new PostSlideshow(t)
      })
    }

    // All pages
    import('@js/modules/pageLoader').then(function (module) {
      const PageLoader = module.default
      t.PageLoader = new PageLoader(t)
    })
    // if ( !this.isIE ) {
    //   this.modulesToInit['SmoothScroll'] = 1
    //   import('@js/modules/smoothScroll').then(function (module) {
    //     const SmoothScroll = module.default
    //     t.SmoothScroll = new SmoothScroll(t)
    //   })
    // }
    import('@js/modules/gallery').then(function (module) {
      const Gallery = module.default
      t.Gallery = new Gallery(t)
    })
    import('@js/modules/lightbox').then(function (module) {
      const Lightbox = module.default
      t.Lightbox = new Lightbox(t)
    })

    this.loadTotal += Object.keys(this.modulesToInit).length
    this.copyright()
  },

  // getScrollY () {
  //   return window.pageYOffset || document.documentElement.scrollTop;
  // },

  // Init Quicklink
  // => prefetch in-viewport links during idle time
  // https://github.com/GoogleChromeLabs/quicklink
  initQuicklink () {
    window.addEventListener('load', () => {
      let options = isDefined(_quicklinkOptions) ? _quicklinkOptions : {
        el: body,
        // origins: [
        //   site_baseurl,
        //   // site_url.replace(/(^\w+:|^)\/\//, '')
        // ], // default to : [location.hostname]
        ignores: [
          /\/api\/?/,
          /\/wp-admin\/?/,
          /\/wp-login\/?/,
          uri => uri.includes('mailto:'),
          uri => uri.includes('.zip'),
          uri => uri.includes('#'),
          (uri, elem) => elem.hasAttribute('noprefetch')
        ]
      };
      options.ignores.push(uri => uri.includes('#')); // Don't prefetch url that contains #
      options.ignores.push((uri, elem) => elem.hasAttribute('noprefetch')); // Don't prefetch elem that contains noprefetch attribute

      quicklink(options)
    })
  },

  disableScroll() {
    if (window.addEventListener) // older FF
      window.addEventListener('DOMMouseScroll', this.preventDefault, false)
    window.onwheel      = this.preventDefault // modern standard
    window.onmousewheel = document.onmousewheel = this.preventDefault // older browsers, IE
    window.ontouchmove  = this.preventDefault // mobile
    document.onkeydown  = this.preventDefaultForScrollKeys
  },

  enableScroll() {
    if (window.removeEventListener)
      window.removeEventListener('DOMMouseScroll', this.preventDefault, false)
    window.onmousewheel = document.onmousewheel = null
    window.onwheel      = null
    window.ontouchmove  = null
    document.onkeydown  = null
  },

  preventDefault(e) {
    e = e || window.event
    if (e.preventDefault) {
      e.preventDefault()
    }
    e.returnValue = false
  },

  preventDefaultForScrollKeys(e) {
    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    const keys = {37: 1, 38: 1, 39: 1, 40: 1}

    if (keys[e.keyCode]) {
      preventDefault(e)
      return false
    }
  },

  displayLoadProgress () {
    let progress = 0,
        progress_base = 0
    if ( this.loadTotal > 0 ) {
      progress_base = this.loadProgress / this.loadTotal
      progress = progress_base * 100
    }

    this.pageloaderProgress.style.transform = 'scaleX(' + progress_base + ')'
  },

  checkAppLoaded () {
    const t = this
    let isLoaded = true

    if (isLoaded && this.modulesToInit['PageLoader']) {
      isLoaded = typeof this.PageLoader != 'undefined' && this.PageLoader.isLoaded
    }
    if (isLoaded && this.modulesToInit['Gallery']) {
      isLoaded = typeof this.Gallery != 'undefined' && this.Gallery.isLoaded
    }
    if (isLoaded && this.modulesToInit['Lightbox']) {
      isLoaded = typeof this.Lightbox != 'undefined' && this.Lightbox.isLoaded
    }
    // if (isLoaded && this.modulesToInit['Parallax']) {
    //   isLoaded = typeof this.Parallax != 'undefined' && this.Parallax.isLoaded
    // }
    if (isLoaded && this.modulesToInit['ScrollReveal']) {
      isLoaded = typeof this.ScrollReveal != 'undefined' && this.ScrollReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['BlockReveal']) {
      isLoaded = typeof this.BlockReveal != 'undefined' && this.BlockReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['SliceReveal']) {
      isLoaded = typeof this.SliceReveal != 'undefined' && this.SliceReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['PostSlideshow']) {
      isLoaded = typeof this.PostSlideshow != 'undefined' && this.PostSlideshow.isLoaded
    }
    if (isLoaded && this.modulesToInit['SmoothScroll']) {
      isLoaded = typeof this.SmoothScroll != 'undefined' && this.SmoothScroll.isLoaded
    }
    if (isLoaded && this.modulesToInit['SvgAnimate']) {
      isLoaded = typeof this.SvgAnimate != 'undefined' && this.SvgAnimate.isLoaded
    }

    this.displayLoadProgress()

    if ( isLoaded ) {
      this.body.classList.remove(CLASSES.loading)
      document.getElementById('main').classList.add(CLASSES.animation)

      setTimeout(() => {
        t.body.classList.add(CLASSES.loaded)
        // document.querySelector('#pageloader').remove()

        if ( this.modulesToInit['SvgAnimate'] ) t.SvgAnimate.onAppLoaded()
        if ( this.modulesToInit['ScrollReveal'] ) t.ScrollReveal.init()
        // if ( this.modulesToInit['BlockReveal'] ) t.BlockReveal.init()
        // if ( this.modulesToInit['SliceReveal'] ) t.SliceReveal.init()
        if ( this.modulesToInit['SmoothScroll'] ) t.SmoothScroll.onAppLoaded()

        new base.FauxLink()

        new base.PositionFull()
        new base.Tabs()
        new base.Toggles()
      // }, variables.transition + variables.transition_fast)
      }, variables.transition)
    }
  },

  checkModuleLoaded (name) {
    if (this.modulesToInit[name]) {
      return (typeof this[name] != 'undefined' && this[name].isLoaded)
    }
    return true
  },

  // check for SW support and register
  registerServiceWorker () {
    if ('serviceWorker' in navigator) {
      const t = this
      const scope = '/';

      // Delay registration until after the page has loaded, to ensure that our
      // precaching requests don't degrade the first visit experience.
      // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
      window.addEventListener('load', function () {
        // Your service-worker.js *must* be located at the top-level directory relative to your site.
        // It won't be able to control pages unless it's located at the same level or higher than them.
        // See https://github.com/slightlyoff/ServiceWorker/issues/468
        navigator.serviceWorker.register(site_url + '/sw.js', { scope: scope }).then(function(reg) {
          // debug('Service Worker registered successfully.')

          // updatefound is fired if service-worker.js changes.
          // reg.onupdatefound = function() {
          //   // The updatefound event implies that reg.installing is set; see
          //   // https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
          //   var installingWorker = reg.installing

          //   installingWorker.onstatechange = function() {
          //     switch (installingWorker.state) {
          //       case 'installed':
          //         if (navigator.serviceWorker.controller) {
          //           // At this point, the old content will have been purged and the fresh content will
          //           // have been added to the cache.
          //           // It's the perfect time to display a "New content is available; please refresh."
          //           // message in the page's interface.
          //           debug('New or updated content is available.')
          //         } else {
          //           // At this point, everything has been precached.
          //           // It's the perfect time to display a "Content is cached for offline use." message.
          //           debug('Content is now available offline!')
          //         }
          //         break;

          //       case 'redundant':
          //         console.error('The installing service worker became redundant.')
          //         break;
          //     }
          //   };
          // };
        }).catch(function(e) {
            console.error('Error during service worker registration:', e)
        })
      })
    }
  },

  copyright () {
    const style = 'color: #fff; background: #ffc308; padding: 4px 8px; border-left: 5px solid #282828; border-right: 5px solid #282828'
    debug('%cUne création Boite à Oeufs', style)
    debug('%c> Site : https://www.boite-a-oeufs.com', style)
    debug('%c> Twitter : https://twitter.com/BoiteOeufs', style)
    debug('%c> Facebook : https://www.facebook.com/boiteoeufs/', style)

    debug('%cDesign par Julian Camaret', style)
    debug('%c> Twitter : https://twitter.com/Jus_motion', style)

    debug('%cDéveloppement par Romain Murschel', style)
    debug('%c> Site : https://romainmurschel.fr', style)
    debug('%c> Twitter : https://twitter.com/balthorg', style)
  }
}

// Kick shit off
App.init()
