import Lenis from '@studio-freight/lenis'

export default class SmoothScroll {
  constructor (App) {
    this.App = App

    this.init()
  }

  init () {
    const lenis = new Lenis({
      // wrapper: window, // The element that will be used as the scroll container
      // content: document.documentElement, // The element that contains the content that will be scrolled, usually `wrapper`'s direct child
      // wheelEventsTarget: wrapper, // The element that will listen to `wheel` events
      lerp: 0.15, // Default: 0.1 - Linear interpolation (lerp) intensity (between 0 and 1)
      // duration: 1.2, // The duration of scroll animation (in seconds). Useless if lerp defined
      // easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // he easing function to use for the scroll animation, our default is custom but you can pick one from Easings.net. Useless if lerp defined, https://easings.net/en
      // orientation: 'vertical', // The orientation of the scrolling. Can be `vertical` or `horizontal`
      // gestureOrientation: 'vertical', // The orientation of the gestures. Can be `vertical`, `horizontal` or `both`
      smoothWheel: true, // Whether or not to enable smooth scrolling for mouse wheel events.
      // smoothTouch: false, // Whether or not to enable smooth scrolling for touch events.
      // syncTouch: false, // Mimic touch device scroll while allowing scroll sync (can be unstable on iOS<16)
      // syncTouchLerp: 0.1, // Lerp applied during `syncTouch` inertia
      // touchInertiaMultiplier: 35, // Manage the the strength of `syncTouch` inertia
      // wheelMultiplier: 1, // The multiplier to use for mouse wheel events
      // touchMultiplier: 2, // The multiplier to use for touch events
      // normalizeWheel: false, // Normalize wheel inputs across browsers (not reliable atm)
      // infinite: false, // Enable infinite scrolling!
      // autoResize: true, // Resize instance automatically based on ResizeObserver. If false you must resize manually using .resize()
    })

    // lenis.on('scroll', (e) => {
    //   console.log(e)
    // })

    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    // Scroll to anchors
    document.querySelectorAll('a[href^="#"]:not(.cn-button)').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        lenis.scrollTo(this.getAttribute('href'), {
          // offset: this.#bounds.headerH,
          duration: 1.5,
          // onComplete: () => {
          //   console.log('end of lenis scrollTo');
          // }
        });
      });
    });

    // Scroll to top
    // document.querySelector('.c-back-to-top').addEventListener('click', (e) => {
    //   e.preventDefault();
    //   lenis.scrollTo('top', {
    //     duration: 1.5,
    //   });
    // });
  }

}