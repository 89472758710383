// import debounce from 'lodash/debounce'
// import variables from '@js/variables'
// import Util from './util'
// import debug from '@js/modules/debug'

const SELECTORS = {
  nav: '#nav',
  navmain: '#navmain',
  menu: '#menu',
  navLink: '.nav__link',
  toggleBtn: '#menu-toggle',
}

const CLASSES = {
  menuListVisible: 'nav__list--visible',
  menuOpen: 'menu-open'
}

export default class Navigation {
  constructor (App) {
    this.App = App
    this.nav        = document.querySelector(SELECTORS.nav)
    this.menu       = document.querySelector(SELECTORS.navmain)
    this.toggleBtn  = document.querySelector(SELECTORS.toggleBtn)

    if (this.menu) {
      this.init()
    }
  }

  init () {
    this.isMenuOpen = false
    this.menu       = document.querySelector(SELECTORS.navmain)
    this.toggleBtn  = document.querySelector(SELECTORS.toggleBtn)
    // this.navLinks   = Array.from( this.menu.querySelectorAll(SELECTORS.navLink) )

    this.toggleBtn.addEventListener( 'click', () => this.toggleMenu() )
    document.addEventListener( 'keydown', e => this.handleTabPress(e) )
  }

  toggleMenu () {
    this.isMenuOpen = !this.isMenuOpen;

    if ( this.isMenuOpen ) {
      this.App.disableScroll()
    } else {
      this.App.enableScroll()
    }

    window.setTimeout(() => {
      this.menu.classList.toggle( CLASSES.menuListVisible );
    }, 50)

    this.toggleBtn.setAttribute( 'aria-expanded', String(this.isMenuOpen) )
    document.body.classList.toggle( CLASSES.menuOpen )
  }

  handleTabPress (e) {
    if (!this.isMenuOpen || e.ctrlKey || e.metaKey || e.altKey) {
      return
    }

    // const lastLink = this.navLinks[this.navLinks.length - 1]
    switch (e.keyCode) {
      case 27: // ESC
        this.toggleMenu()
        this.toggleBtn.focus()
        break

      // case 9: // TAB
      //     if (e.shiftKey) {
      //         if (document.activeElement === this.toggleBtn) {
      //             lastLink.focus()
      //             e.preventDefault()
      //         }
      //     } else if (document.activeElement === lastLink) {
      //         this.toggleBtn.focus()
      //         e.preventDefault()
      //     }
      //     break

      default:
          break
    }
  }
}
